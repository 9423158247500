h1,
h2,
h3,
h4,
h5,
h6,
span label {
  text-transform: capitalize;
}

.App {
  text-align: center;
}

.text-capitalize {
  text-transform: 'capitalize';
}

.cursor-pointer {
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 992px) {
  .login-image {
    display: none !important;
  }
}

.styles-module_wrapper__1I_qj {
  z-index: 100 !important;
  display: flex;
  align-items: center;
  position: fixed;
  padding: 0px 60px 0px 60px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000d9 !important;
  box-sizing: border-box;
}

.MuiBox-root {
  border-radius: 7px !important;
}

select:disabled {
  background-image: none !important;
}

.css-78me8v-MuiFormControl-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  margin: 0 !important;
  width: 100%;
}

.css-1tm81ay {
  width: 96% !important;
}

.css-1ggoop8 svg {
  font-size: 18px !important;
  cursor: pointer;
  padding: 4px;
}

.multi-select-dropdown {
  min-height: 2rem;
  max-height: 10rem;
  position: absolute;
  z-index: 40;
  background-color: #fff;
  border: 1px solid #e6edef;
  width: 97%;
  padding: 7px;
  overflow-y: scroll;
}

.selected_skill {
  background-color: #dadae9;
}

.smooth-collapse {
  height: 0;
  visibility: hidden;
  transition: all .5s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.smooth-collapse-active {
  height: 100%;
  visibility: visible;
  transition: all .5s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
ul{
  list-style: auto;
}