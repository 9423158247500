.message-box{
    border-radius: 5px;
    min-width: 1rem !important;
    width: auto !important;
    max-width: 81% !important;
}

.course__sidebar-search input {
    width: 100%;
    height: 50px;
    line-height: 58px;
    padding: 0 45px 0 30px;
    background: #f3f4f8;
    border-radius: 4px;
    border: 2px solid #f3f4f8;
    outline: none;
} 

.course__sidebar-search button {
    width: 18px;
    height: 100%;
    background: #f3f4f8;
    position: absolute;
    top: 0;
    right: 0;
}