.remove-btn{
    visibility: visible;
    position: absolute;
    background: white;
    width: 8rem;
    height: 7rem;
    /* left: 10px; */
    opacity: .5;
    display: flex;
    /* top: 10px; */
    justify-content: center;
    align-items: center;
    transition: all .5s ease-in ;
}
.remove-btn-hidden{
    visibility: visible;
    position: absolute;
    background: white;
    width: 1rem;
    height: 7rem;
    /* left: 10px; */
    opacity: 0;
    display: flex;
    /* top: 10px; */
    justify-content: center;
    align-items: center;
    transition: all .5s ease-in ;
}

 .icon{
    font-size: 2rem;
    color: rgb(135, 135, 135);
    cursor: pointer;
    transition: .3s ease-in;
    padding: 1.5rem;
}
 .icon:hover{
    font-size: 2rem;
    color: rgb(0, 0, 0);
    cursor: pointer;
    transition: .3s ease-in;
    padding: 1.5rem;
}

.product-images-box{
    margin-top: 1rem;
    background: #ebebeb;
    padding: 10px;
    border-radius: 4px;
    min-height: 8.5rem;
}

  input[type=file] {
    cursor: pointer;
    left: 12px;
    top: 0;
    opacity: 0;
    position: absolute;
    font-size: inherit;
    width: 6.5rem;
    height: 2.25rem;
  }

  .primary-img {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: -51px;
    background: var(--success);
    font-size: 15px;
    color: #0d0e12;
    padding: 1px;
    top: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
    margin-top: -5px;
}